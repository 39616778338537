import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import theme from "../styles/theme";

const Clients = ({ dataTitle, dataClients, mt, mb }) => {
  return (
    <section
      css={`
        margin-top: ${mt || `100px`};
        margin-bottom: ${mb || `0`};
        flex-direction: column;
      `}
    >
      <h2>{dataTitle || "References"}</h2>
      <div
        css={`
          display: grid;
          justify-content: center;
          grid-gap: 20px;
          grid-template-columns: repeat(3, 1fr);
          ${theme.tablet} {
            grid-template-columns: repeat(2, 1fr);
          }
          ${theme.mobile} {
            grid-template-columns: repeat(1, 1fr);
          }
          .itemBg {
            width: 100%;
            height: 240px;
            max-height: 240px;
            display: flex;
          }
          .linkBox {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            overflow: hidden;
            .img {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden img {
                object-fit: cover !important;
                flex-shrink: 0;
                min-width: 100%;
                min-height: 100%;
              }
            }
            .layer {
              width: 100%;
              height: 240px;
              position: absolute;
              background: #eb008b;
              mix-blend-mode: multiply;
            }
            .arrow {
              margin-top: 15px;
            }
            .content {
              position: absolute;
              text-align: center;
              padding: 20px;
            }
            h3 {
              margin: 0;
              margin-top: 15px;
              font-weight: 900;
              font-size: 32px;
              line-height: 110.4%;
            }
            p {
              font-size: 18px;
              line-height: 118%;
            }
            ${theme.laptop} {
              > div h3 {
                font-size: 26px;
              }
            }
            .contentLayer {
              display: none;
              width: 100%;
              height: 100%;
              position: absolute;
              justify-content: space-around;
              align-items: center;
              padding: 20px;
              text-align: center;
              box-sizing: border-box;
              p {
                font-size: 16px;
              }
            }
            @media (min-width: 600px) {
              &:hover .contentLayer {
                display: flex;
                flex-direction: column;
                background: #474747 !important;
                transition: background 0.2s;
              }
            }
          }
        `}
      >
        {dataClients.edges.map(item => {
          return (
            <BackgroundImage
              Tag="div"
              className="itemBg"
              fluid={item.node.thumbnail.fluid}
            >
              <Link className="linkBox" to={`/references/${item.node.slug}`}>
                <div className="layer" />

                <div className="content">
                  <h3>{item.node.title}</h3>
                  <svg
                    className="arrow"
                    width="46"
                    height="46"
                    viewBox="0 0 63 63"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter0_d)">
                      <circle
                        cx="31.5"
                        cy="29.5"
                        r="27.0417"
                        stroke="white"
                        stroke-width="4.91667"
                      />
                      <path
                        d="M23.1236 16.0278L42.4802 27.6697C44.0686 28.625 44.0686 30.9277 42.4802 31.883L23.1236 43.525"
                        stroke="white"
                        stroke-width="4.91667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d"
                        x="0.361111"
                        y="0"
                        width="62.2778"
                        height="62.2778"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="1.63889" />
                        <feGaussianBlur stdDeviation="0.819444" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div className="contentLayer">
                  <p>{item.node.text}</p>
                  <Link
                    to={`/references/${item.node.slug}`}
                    className="button whiteOutlines"
                  >
                    Read more
                  </Link>
                </div>
              </Link>
            </BackgroundImage>
          );
        })}
        <div
          className="itemBg"
          css={`
            background: ${theme.pink} !important;
            .button {
              margin-top: 15px;
            }
          `}
        >
          <div className="linkBox">
            <div className="content">
              <h3>Are you next?</h3>
              <Link to="/contact" className="button whiteOutlines">
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
